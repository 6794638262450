import { Popover, Transition } from "@headlessui/react";
import classNames from "clsx";
import throttle from "lodash/throttle";
import Link from "next/link";
import { useTranslations } from "next-intl";
import { Fragment, useEffect, useLayoutEffect, useState } from "react";
import {
  HiMenu as MenuIcon,
  HiX as XIcon,
  HiChevronDown as ChevronDownIcon,
} from "react-icons/hi";

import { ProductSwitcher } from "@shared/components";
import { Button, Container, PicterContestsLogo, Text } from "@shared/elements";

import { MESSAGE_PREFIX, paths } from "src/constants";


const HOST_LOGIN_LINK = `/login?redirectTo=${process.env.NEXT_PUBLIC_CURATION_APP_URL}&utm_medium=site&utm_source=picter_public_contest_list&utm_campaign=host_and_jury_login&utm_content=nav_bar`;

export default function Header() {
  const t = useTranslations(`${MESSAGE_PREFIX}components.PublicHeader`);
  const [isOnTop, setIsOnTop] = useState<boolean>(true);

  // SSR doesn't work for useLayoutEffect. This is a workaround used by many libraries.
  const hybridSideEffect =
    typeof window === "undefined" ? useEffect : useLayoutEffect;

  hybridSideEffect(() => {
    const scrollHandler = throttle(() => {
      if (isOnTop && window.scrollY > 30) {
        setIsOnTop(false);
      } else if (!isOnTop && window.scrollY < 30) {
        setIsOnTop(true);
      }
    }, 100);
    window.addEventListener("scroll", scrollHandler);
    return () => window.removeEventListener("scroll", scrollHandler);
  }, [isOnTop]);

  return (
    <>
      <Container
        className={classNames(
          "text-secondary-900 fixed w-full z-10 transition-colors duration-250",
          isOnTop ? "bg-transparent" : "bg-white shadow",
        )}
      >
        <Popover className="relative">
          {({ open }) => (
            <>
              <div className="flex justify-between items-center py-4 lg:justify-start md:space-x-10">
                <div className="flex justify-start items-center lg:w-0 lg:flex-1 space-x-10">
                  {/* Logo */}
                  <span>
                    <span className="sr-only">Picter Contests</span>
                    <PicterContestsLogo
                      className="h-7 sm:h-8 w-auto"
                      logoClassName="!text-secondary-900"
                    />
                  </span>
                  {/* Non mobile: Products dropown */}
                  <Popover.Group as="nav" className="hidden lg:flex space-x-10">
                    <Popover className="relative">
                      {({ open }) => (
                        <>
                          <Popover.Button
                            className={classNames("group")}
                            as={Button}
                            variant="text"
                            size="lg"
                          >
                            {t("products")}
                            <ChevronDownIcon
                              className={classNames("ml-2 h-5 w-5")}
                              aria-hidden="true"
                            />
                          </Popover.Button>

                          <Transition
                            show={open}
                            as={Fragment}
                            enter="transition ease-out duration-200"
                            enterFrom="opacity-0 translate-y-1"
                            enterTo="opacity-100 translate-y-0"
                            leave="transition ease-in duration-150"
                            leaveFrom="opacity-100 translate-y-0"
                            leaveTo="opacity-0 translate-y-1"
                          >
                            <Popover.Panel
                              static
                              className="absolute z-10 left-1/2 transform -translate-x-1/2 mt-3 px-2 w-screen max-w-sm sm:px-0"
                            >
                              <div className="rounded shadow-lg ring-1 ring-black ring-opacity-5 overflow-y-auto max-h-[calc(100vh-70px)]">
                                <ProductSwitcher className="bg-white py-7 p-5" />
                              </div>
                            </Popover.Panel>
                          </Transition>
                        </>
                      )}
                    </Popover>
                  </Popover.Group>
                </div>
                {/* Mobile only: menu icon */}
                <div className="-mr-2 -my-2 lg:hidden">
                  <Popover.Button className="rounded-md p-2 inline-flex items-center justify-center text-secondary-900 hover:text-black focus:outline-none focus-visible:ring-2 focus-visible:ring-inset focus-visible:ring-primary-500">
                    <span className="sr-only">{t("openMenu")}</span>
                    <MenuIcon className="h-6 w-6" aria-hidden="true" />
                  </Popover.Button>
                </div>
                {/* Non mobile: Sign in */}
                <div className="hidden lg:flex items-center justify-end md:flex-1 lg:w-0 space-x-4">
                  <Button
                    variant="text"
                    as={Link}
                    size="lg"
                    href={HOST_LOGIN_LINK}
                    className="text-secondary-900"
                  >
                    {t("hostLogin")}
                  </Button>
                  <Button
                    variant="primary"
                    as={Link}
                    size="lg"
                    href={paths.login}
                  >
                    {t("creatorLogin")}
                  </Button>
                </div>
              </div>

              {/* Mobile: Products dropown */}
              <Transition
                show={open}
                as={Fragment}
                enter="duration-200 ease-out"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="duration-100 ease-in"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Popover.Panel
                  focus
                  static
                  className="absolute top-0 inset-x-0 py-2 -mx-2 transition transform origin-top-right lg:hidden"
                >
                  <div className="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 bg-white max-h-screen overflow-y-auto">
                    <div className="pt-5 px-5">
                      <div className="flex items-center justify-between">
                        <div>
                          <Text className="text-base leading-6 font-medium text-gray-900">
                            {t("switchProduct")}
                          </Text>
                        </div>
                        <div className="-mr-2">
                          <Popover.Button className="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-primary-500">
                            <span className="sr-only">{t("closeMenu")}</span>
                            <XIcon className="h-6 w-6" aria-hidden="true" />
                          </Popover.Button>
                        </div>
                      </div>
                    </div>
                    <div className="pt-3">
                      <ProductSwitcher className="py-3 p-6" />

                      {/* Divider */}
                      <div className="w-full h-[2px] bg-gray-50 mt-3"></div>

                      <div className="px-5 py-6 space-y-6">
                        <Button
                          variant="primary"
                          as={Link}
                          size="lg"
                          className="w-full"
                          href={paths.login}
                        >
                          {t("creatorLogin")}
                        </Button>
                        <Text
                          as="p"
                          weight="medium"
                          color="muted"
                          className="text-center"
                        >
                          {t.rich("alternativeHostLogin", {
                            // eslint-disable-next-line react/display-name
                            link: (children) => (
                              <Link
                                href={HOST_LOGIN_LINK}
                                className="text-primary-600"
                              >
                                {children}
                              </Link>
                            ),
                          })}
                        </Text>
                      </div>
                    </div>
                  </div>
                </Popover.Panel>
              </Transition>
            </>
          )}
        </Popover>
      </Container>
    </>
  );
}

Header.messages = [
  `${MESSAGE_PREFIX}components.PublicHeader`,
  ...ProductSwitcher.messages,
];
