import { GetStaticPropsContext } from "next";
import Head from "next/head";
import Image from "next/image";
import Link from "next/link";
import { useRouter } from "next/router";
import { useTranslations } from "next-intl";

import {
  AdCard,
  GenericAd,
  Ratio,
  SidebarOverlay,
  QuickInfo,
} from "@shared/components";
import { Text, Container, Grid } from "@shared/elements";
import { useAd , graphqlFetch, useGraphSWR } from "@shared/hooks";
import { event } from "@shared/tracking";
import { VideoFile } from "@shared/types";

import {
  IndexCallQuery,
  IndexCallsQuery,
  type TIndexCallQuery,
  type TIndexCallsQuery,
} from "__generated__/types";

import CallCard from "src/components/CallCard";
import DefaultOpenGraphTags from "src/components/DefaultOpenGraphTags";
import Footer from "src/components/Footer";
import NewsletterForm from "src/components/NewsletterForm";
import PublicHeader from "src/components/PublicHeader";

import * as constants from "src/constants";
import { getCallCardLink } from "src/helpers";
import bundleMessages from "src/utils/bundleMessages";



export default function Index({ calls }: TIndexCallsQuery) {
  const t = useTranslations(`${constants.MESSAGE_PREFIX}pages.index`);
  const gridAds = [useAd("Contest Grid Card"), useAd("Contest Grid Card", 1)];
  const { ad: bannerAd } = useAd("Contest Grid Banner");
  const router = useRouter();

  const callId = router.query.quickInfo as string | undefined;
  const { data: callData } = useGraphSWR<TIndexCallQuery>(IndexCallQuery, {
    id: callId,
  });

  const quickInfoCall = callData?.call;

  const onQuickInfoClose = () => {
    event("quickInfo_closed", {
      call: quickInfoCall?.title?.en,
    });
    router.replace({ pathname: router.pathname, query: {} }, undefined, {
      shallow: true,
    });
  };
  return (
    <>
      <Head>
        <title key="title">Picter Contests</title>
        <DefaultOpenGraphTags />
      </Head>
      <PublicHeader />
      <Container className="bg-gradient-to-r from-primary-300 to-blue-200 text-secondary-900 pt-24 lg:pb-14 lg:overflow-hidden">
        <div className="lg:grid lg:grid-cols-2 lg:gap-8">
          <div className="mx-auto max-w-md sm:max-w-3xl lg:flex lg:items-center">
            <div className="md:pt-24 lg:pb-24">
              <Text
                as="h1"
                size="6xl"
                color="current"
                weight="bold"
                className="mt-4 sm:mt-5 lg:mt-6 leading-10 sm:leading-none"
              >
                {t("hero.header")}
              </Text>
              <Text
                as="p"
                size="lg"
                color="current"
                className="mt-3 sm:mt-5 sm:text-xl"
              >
                {t("hero.subline")}
              </Text>
              <div className="mt-10 sm:mt-12">
                <Text weight="medium" color="current">
                  {t("hero.newsletterHead")}
                </Text>
                <NewsletterForm />
              </div>
            </div>
          </div>
          <div className="mt-12 lg:m-0 lg:relative">
            <div className="mx-auto max-w-md px-4 sm:max-w-2xl sm:px-6 lg:max-w-none lg:px-0">
              <Image
                className="w-full lg:absolute lg:inset-y-0 lg:left-0 lg:h-full lg:w-auto lg:max-w-none"
                src="/assets/logo-grid-perspective.svg"
                width={1000}
                height={1000}
                alt="Brands using Picter"
              />
            </div>
          </div>
        </div>
      </Container>
      <Container className="mt-14">
        <Text as="h2" weight="bold" size="3xl">
          {t("listHeader")}
        </Text>
        <Grid className="mt-12 sm:gap-y-14">
          {calls.slice(0, 1).map((call) => (
            <Grid.Item key={call.id}>
              <Link
                data-testid={getCallCardLink(call)}
                href={getCallCardLink(call)}
                shallow
                scroll={false}
              >
                <CallCard call={call} />
              </Link>
            </Grid.Item>
          ))}
          {gridAds[0]?.ad && (
            <Grid.Item key={gridAds[0]?.ad.id}>
              <AdCard
                title={gridAds[0]?.ad.content.headline}
                campaign={gridAds[0]?.ad.campaign}
                url={gridAds[0]?.ad.url}
                type={gridAds[0]?.ad.content.mediaType}
                src={gridAds[0]?.ad.content.files[0].src}
                impressionUrl={gridAds[0]?.ad.impressionUrl}
                clickUrl={gridAds[0]?.ad.clickUrl}
                poster={
                  gridAds[0]?.ad.content.mediaType === "video"
                    ? (gridAds[0]?.ad.content.files[0] as VideoFile).poster
                    : undefined
                }
              />
            </Grid.Item>
          )}
          {calls.slice(1, 6).map((call) => (
            <Grid.Item key={call.id}>
              <Link
                data-testid={getCallCardLink(call)}
                href={getCallCardLink(call)}
                shallow
                scroll={false}
              >
                <CallCard call={call} />
              </Link>
            </Grid.Item>
          ))}
          {gridAds[1]?.ad && (
            <Grid.Item key={gridAds[1]?.ad.id}>
              <AdCard
                title={gridAds[1]?.ad.content.headline}
                campaign={gridAds[1]?.ad.campaign}
                url={gridAds[1]?.ad.url}
                type={gridAds[1]?.ad.content.mediaType}
                src={gridAds[1]?.ad.content.files[0].src}
                impressionUrl={gridAds[1]?.ad.impressionUrl}
                clickUrl={gridAds[1]?.ad.clickUrl}
                poster={
                  gridAds[1]?.ad.content.mediaType === "video"
                    ? (gridAds[1]?.ad.content.files[0] as VideoFile).poster
                    : undefined
                }
              />
            </Grid.Item>
          )}
          {calls.slice(6).map((call) => (
            <Grid.Item key={call.id}>
              <Link
                data-testid={getCallCardLink(call)}
                href={getCallCardLink(call)}
                shallow
                scroll={false}
              >
                <CallCard call={call} />
              </Link>
            </Grid.Item>
          ))}
        </Grid>
        {bannerAd && (
          <GenericAd
            key={bannerAd.id}
            campaign={bannerAd.campaign}
            type={bannerAd.content.mediaType}
            src={bannerAd.content.files.map(({ src }) => src)}
            impressionUrl={bannerAd.impressionUrl}
            clickUrl={bannerAd.clickUrl}
            poster={
              bannerAd.content.mediaType === "video"
                ? bannerAd.content.files.map(
                    (adFile) => (adFile as VideoFile).poster,
                  )
                : undefined
            }
            ratio={[Ratio.Square, Ratio.FourByOne]}
            className="mt-10 pt-10 border-gray-300 border-t"
          />
        )}
      </Container>
      {/* <Testimonials /> */}
      <Container className="bg-gradient-to-r from-primary-300 to-blue-200 text-secondary-900 mt-14">
        <div className="py-24 lg:py-32 lg:flex lg:items-center">
          <div className="lg:w-0 lg:flex-1">
            <Text
              as="h2"
              weight="bold"
              color="current"
              size="4xl"
              className="leading-9 sm:leading-10"
            >
              {t("newsletterCopy.header")}
            </Text>
            <Text as="p" color="current" size="lg" className="mt-3 max-w-3xl">
              {t("newsletterCopy.claim")}
            </Text>
          </div>
          <div className="mt-8 lg:mt-0 lg:ml-8">
            <NewsletterForm />
          </div>
        </div>
      </Container>
      <Footer />
      <SidebarOverlay
        show={Boolean(quickInfoCall)}
        size="wide"
        onClose={onQuickInfoClose}
      >
        {quickInfoCall && (
          <QuickInfo data={quickInfoCall} showFooterLogo={false} />
        )}
      </SidebarOverlay>
    </>
  );
}

export async function getStaticProps({ locale }: GetStaticPropsContext) {
  const { calls }: TIndexCallsQuery = await graphqlFetch(
    JSON.stringify({
      query: IndexCallsQuery,
      variables: { limit: 100 },
    }),
  );
  const messages = await bundleMessages(locale, [
    `${constants.MESSAGE_PREFIX}pages.index`,
    ...AdCard.messages,
    ...CallCard.messages,
    ...Footer.messages,
    ...GenericAd.messages,
    ...NewsletterForm.messages,
    ...PublicHeader.messages,
    ...SidebarOverlay.messages,
    ...QuickInfo.messages,
  ]);
  return {
    props: {
      calls,
      messages,
    },
    // Next.js will attempt to re-generate the page:
    // - When a request comes in
    // - At most once every 60 seconds
    revalidate: 60, // In seconds
  };
}
