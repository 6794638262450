import { Portal, Transition } from "@headlessui/react";
import classNames from "clsx";
import { useFormik } from "formik";
import { useTranslations } from "next-intl";
import { Fragment, useEffect, useState } from "react";
import { HiOutlineInboxIn, HiX } from "react-icons/hi";

import { Anchor, Input, Button, Text } from "@shared/elements";
import { event } from "@shared/tracking";

import { MESSAGE_PREFIX } from "src/constants";

type NewsletterFormProps = {
  className?: string;
};

export default function NewsletterForm({
  className,
  ...rest
}: NewsletterFormProps) {
  const t = useTranslations(`${MESSAGE_PREFIX}components.NewsletterForm`);
  const [submitted, setSubmitted] = useState(false);

  useEffect(() => {
    let timeout;
    if (submitted) {
      timeout = setTimeout(() => {
        setSubmitted(false);
      }, 5000);
    }
    return () => {
      clearTimeout(timeout);
    };
  }, [submitted]);

  const { isSubmitting, submitCount, errors, handleSubmit, getFieldProps } =
    useFormik({
      initialValues: { email: "" },
      validate(values) {
        const validationErrors: { email?: string } = {};
        if (!values.email) {
          validationErrors.email = t("emailInvalid") as string;
        } else if (
          !/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(
            values.email,
          )
        ) {
          validationErrors.email = t("emailInvalid") as string;
        }
        return validationErrors;
      },
      async onSubmit(values, actions) {
        const response = await fetch("/api/newsletter", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            ...values,
          }),
        });
        if (!response.ok) {
          const body = await response.json();
          actions.setFieldError("email", `Error: ${body?.message}`);
          actions.setSubmitting(false);
        } else {
          event("newsletter_subscribed", { form: "contest_grid" });
          setSubmitted(true);
          actions.setSubmitting(false);
        }
      },
    });
  return (
    <div className={classNames(className)} {...rest}>
      <form noValidate className="sm:flex sm:items-end" onSubmit={handleSubmit}>
        <Input
          hiddenLabel
          label={t("labelEmail") as string}
          placeholder={t("placeholderEmail") as string}
          type="email"
          autoComplete="email"
          className="w-full sm:max-w-xs lg:max-w-md flex-grow"
          size="lg"
          required
          {...getFieldProps("email")}
          error={submitCount > 0 ? errors.email : undefined}
        />
        <div className="mt-3 sm:mt-0 sm:ml-3 sm:flex-shrink-0">
          <Button
            type="submit"
            variant="primary"
            size="xl"
            className={classNames("w-full")}
            disabled={isSubmitting}
            inProgress={isSubmitting}
          >
            {t("subscribe")}
          </Button>
        </div>
      </form>
      <Text as="p" size="sm" color="current" className="mt-3">
        {t.rich("privacyHint", {
          // eslint-disable-next-line react/display-name
          link: (children) => (
            <Anchor
              className="font-medium"
              href="https://www.picter.com/privacy-policy"
            >
              {children}
            </Anchor>
          ),
        })}
      </Text>
      <Portal>
        <div
          aria-live="assertive"
          className="fixed inset-0 flex items-end px-4 py-6 pointer-events-none sm:p-6 sm:items-start z-40"
        >
          <div className="w-full flex flex-col items-center space-y-4 sm:items-end">
            <Transition
              show={submitted}
              as={Fragment}
              enter="transform ease-out duration-150 transition"
              enterFrom="translate-y-full sm:translate-y-0 sm:translate-x-full"
              enterTo="translate-y-0 sm:translate-x-0"
              leave="transition ease-in duration-100"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="max-w-sm w-full bg-white shadow-lg rounded-lg pointer-events-auto ring-1 ring-black ring-opacity-5 overflow-hidden">
                <div className="p-4">
                  <div className="flex items-start">
                    <div className="flex-shrink-0">
                      <HiOutlineInboxIn
                        className="h-6 w-6 text-green-400"
                        aria-hidden="true"
                      />
                    </div>
                    <div className="ml-3 w-0 flex-1 pt-0.5">
                      <Text as="p" size="sm" weight="medium">
                        {t("successTitle")}
                      </Text>
                      <Text as="p" size="sm" color="muted" className="mt-1">
                        {t("successMessage")}
                      </Text>
                    </div>
                    <div className="ml-4 flex-shrink-0 flex">
                      <button
                        className="bg-white rounded-md inline-flex text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                        onClick={() => {
                          setSubmitted(false);
                        }}
                      >
                        <span className="sr-only">Close</span>
                        <HiX className="h-5 w-5" aria-hidden="true" />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </Transition>
          </div>
        </div>
      </Portal>
    </div>
  );
}

NewsletterForm.messages = [`${MESSAGE_PREFIX}components.NewsletterForm`];
